import React from 'react';

const HydraIcon = () => (
  <svg className="hbw-icon-svg" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="241.667px" height="235.5px" viewBox="0 0 241.667 235.5" enableBackground="new 0 0 241.667 235.5">
    <path className="hbw-icon-svg-path" fillRule="evenodd" clipRule="evenodd" fill="#fff"
      d="M234.784,61.563c1.813,7.728-1.417,15.498-4.867,22.728c-3.307,6.928-7.986,13.285-13.592,18.822
      c-6.42,6.34-13.518,11.819-21.663,15.438c-9.061,4.02-18.448,7.645-28.637,7.521
      c-6.161-0.068-12.348-0.292-18.462-0.982c-3.209-0.363-6.371-1.69-9.405-2.953c-4.578-1.899-8.994-2.459-12.332,2.634
      c-2.816,4.301-6.283,8.18-9.007,12.527c-1.476,2.36-1.769,5.433-3,7.991c-3.527,7.318-4.02,15.071-3.274,22.924
      c0.33,3.491,1.54,6.903,2.445,10.322c0.38,1.433,1.158,2.775,1.496,4.216c1.958,8.333,7.248,14.495,14.038,18.797
      c5.849,3.707,12.695,5.973,19.991,4.119c4.886-1.238,7.152-5.397,9.763-9.143c4.081-5.845,9.392-9.642,16.736-9.628
      c1.422,0,3.494,0.637,4.075,1.662c0.574,1.01-0.016,3.085-0.718,4.337c-1.166,2.086-2.867,3.858-4.271,5.814
      c-0.931,1.295-1.948,2.597-2.554,4.052c-1.251,3.014,0.208,5.035,3.484,4.824c3.645-0.238,7.284-0.637,10.909-1.096
      c2.312-0.298,4.425-0.28,5.538,2.199c1.082,2.409-0.004,4.195-1.848,5.707c-2.374,1.95-4.664,4.017-7.11,5.864
      c-3.602,2.725-7.822,3.394-12.227,3.828c-3.249,0.313-6.509,1.273-9.589,2.423c-11.303,4.206-22.334,3.91-33.84,0.106
      c-11.454-3.796-19.927-11.073-26.761-20.39c-3.298-4.501-5.046-10.151-7.477-15.289
      c-0.243-0.513-0.288-1.121-0.53-1.633c-6.466-13.666-5.644-28.097-3.379-42.308
      c1.144-7.183,4.183-14.139,7.792-20.77c4.855-8.92,11.089-16.516,18.437-23.33
      c2.44-2.263,2.432-4.956,1.706-7.934c-2.555-10.478-8.086-19.178-15.872-26.4
      c-12.561-11.649-27.284-16.79-44.383-14.025c-4.891,0.79-8.719,3.803-12.546,6.689
      c-1.272,0.956-2.253,2.305-3.518,3.273c-3.321,2.547-3.746,5.832-2.649,9.567
      c0.161,0.551,0.297,1.156,0.624,1.601c4.591,6.265,3.696,12.723,0.532,19.152c-0.696,1.413-2.002,3.232-3.27,3.455
      c-1.241,0.214-3.215-1.053-4.112-2.249c-1.631-2.167-2.767-4.729-3.993-7.183c-2.011-4.021-4.394-4.459-7.2-0.894
      c-1.442,1.832-2.142,4.238-3.385,6.256c-0.966,1.569-1.975,3.596-3.464,4.229c-2.161,0.912-3.738-1.117-5.022-2.764
      c-3.752-4.814-3.691-14.253-0.51-19.53c2.887-4.793,5.669-9.781,7.516-15.032c1.752-4.974,4.958-8.77,8.036-12.677
      c5.517-7.002,13.06-11.46,21.052-14.968c6.708-2.943,14.02-3.366,21.225-3.081c5.921,0.231,12.02,0.545,17.658,2.164
      c6.712,1.931,13.239,4.603,19.423,8.254c8.035,4.746,14.814,10.749,20.751,17.765c3.364,3.974,6.998,7.902,8.526,13.135
      c0.064,0.221,0.14,0.459,0.28,0.63c5.008,6.144,5.46,13.975,7.703,21.134c1.194,3.818,3.747,6.322,7.468,7.451
      c10.065,3.042,20.054,2.778,29.942-0.95c5.235-1.975,10.668-3.583,14.937-7.426c3.798-3.412,7.819-6.717,10.964-10.691
      c3.153-3.989,5.537-8.642,7.906-13.185c2.939-5.643,2.783-11.759,0.293-17.335c-4.079-9.143-4.374-9.008-14.009-11.232
      c-6.235-1.433-10.722-5.518-14.648-10.208c-2.232-2.665-0.163-6.585,3.371-6.554c3.303,0.029,6.602,0.68,9.906,0.747
      c1.561,0.04,3.695-0.134,4.581-1.117c1.52-1.69,0.748-3.967-0.58-5.699c-1.602-2.096-3.551-3.932-5.024-6.106
      c-0.893-1.32-2.063-3.868-1.546-4.444c1.214-1.348,3.308-2.38,5.148-2.579c6.141-0.662,10.657,3.167,14.761,6.838
      c2.713,2.427,4.228,6.191,6.355,9.304c2.381,3.484,4.945,6.857,7.24,10.401c1.347,2.077,2.373,4.375,3.447,6.62
      c0.441,0.922,0.492,2.032,0.95,2.946C234.781,46.719,235.347,53.639,234.784,61.563z"/>
  </svg>
);

export default HydraIcon;
